.editorClassName {
  width: 50%;
  height: 100%;
  max-height: 50%;
  background-color: aqua;
  /* overflow: scroll; */
  overflow: auto;
}
.demo-wrapper{
  width: 100%;
  height: 100%;
  max-height: 90%;
  /* background-color: aqua; */
  overflow: scroll;
  /* overflow: auto; */
}
