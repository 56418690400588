.spinner-wrapper {
  /* top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    margin: auto;
    position: absolute; */
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 80%;
}
