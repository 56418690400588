.react-datepicker__without_border_fullWH {
  width: 100%;
  height: 35px;
  cursor: pointer;
  border-color: black;
  margin: 0px 0px 0px 0px;
  padding-left: 10px;
  font-size: small;
  border-color: lightgrey;
}

.collapse-button {
  display: block;
  width: 100%;
}

.collapse-content.collapsed {
  display: none;
}

.collapsed-content.expanded {
  display: block;
}
