* {
  margin: 5;
  padding: 5;
  /* background-color: aqua; */
  /* z-index: 1; */
}
ul {
  padding: 0;
  margin: 0;
  /* background-color: aqua; */
}
li {
  /* background-color: aqua; */
  list-style: none;
}
li:hover {
  transition: width 0.2s ease-in;
  /* background-color: rgb(247, 247, 247); */
  transform: 10px;
  /* box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19); */
}

body {
  /* background-color: #FAFDFB; */
  font-family: 'Open Sans', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* background-color: aqua; */
  border-radius: 10px;
  display: none;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* background-color: aqua; */
  border-radius: 10px;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  display: none;
  background: #333;
  height: 2px;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb .top-section .toggle-menu-btn {
  height: 2px;
  display: block;
  background: #333;
  /* padding: 10px 10px; */
}

.side-menu {
  /* border-right-width: 1px; */

  -moz-box-shadow: -3px 0 5px 0 #555;
  -webkit-box-shadow: -3px 0 5px 0 #555;
  box-shadow: -3px 0 5px 0 #555;

  position: fixed;
  width: 300px;
  height: 100%;
  background: rgb(255, 255, 255);
  padding: 30px 20px;
  box-sizing: border-box;
  transition: width 0.3s ease-in;
}

.side-menu.inactive {
  width: 80px;
}

.side-menu .top-section {
  position: relative;
}

/* When side bar is visible  */
.side-menu .top-section .toggle-menu-btn {
  /* bottom: 10%; */
  top: 5%;
  left: 113%;
  color: rgb(255, 255, 255);
  cursor: pointer;
  position: absolute;
  transition: left 0.3s ease-in, top 0.3s ease-in;

  /* transform: translateY(20%); */
  /* transform: translateX(110%); */
}

.side-menu.inactive .top-section .toggle-menu-btn {
  /* right: -160px; */
  /* display: none; */
  left: auto;
  position: absolute;
  left: 70px;
  bottom: 100%;
  color: rgb(255, 255, 255);
  transform: translateY(50%);
}

/* Main Side-menu */
.side-menu .main-menu .menu-item {
  color: rgba(51, 51, 51, 0.535);
  display: block;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 9px;
  text-decoration: none;
}

/* when side-menu is selected */
.side-menu .main-menu .menu-item.active {
  /* background: #333; */
  display: block;
  font-size: 14px;
  font-weight: 300;
  border-radius: 1px;
  color: rgb(77, 77, 77);
  padding: 3px 0px 10px 0px;
  transition: padding 0.3s step-start;
  font-family: 'Nunito', 'Segoe UI', arial;
}

/* Side menu including submenu */
.side-menu .main-menu {
  margin: 10px 0;
  padding-top: 10px;
  position: relative;
  /* height: 100%; */
  max-height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;

  transition-duration: 0.4s;
}

.side-menu.inactive .main-menu {
  height: 100%;
  transition-duration: 0.2s;
  transition: height 0.2s ease-in;
}

/* Side menuu icons */
.side-menu .main-menu .menu-item .menu-icon {
  width: 40px;
  height: 40px;
  /* background-color: rgba(159, 159, 167, 0.459); */
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
}

/* Sidemenu names */
.side-menu .main-menu .menu-item span {
  position: absolute;
  display: inline-block;
  line-height: 40px;
  opacity: 1;
  transition-duration: 1.2s;
  transition: opacity 0.2s ease-in;
}

/* Sidemenu when inactive */
.side-menu.inactive .main-menu .menu-item span {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}
.side-menu .main-menu .sub-menu {
  color: rgba(51, 51, 51, 0.535);
  margin-left: 20px;
  border-left: 1px solid rgb(51, 51, 51, 0.535);
  box-sizing: border-box;
  max-height: 0;
  overflow: hidden;
  /* transition: max-height 0.2s ease-in; */
  /* background-color: blueviolet; */
  /* height: 100%; */
  transition: height 3s ease-out;
  padding-left: 25px;

  /* transform: scaleY(1);
    transform: padding-left;
  transform-origin: padding-left; */
  /* transition: margin-left 0.2s ease-out; */
}

.side-menu .main-menu .sub-menu.active {
  padding: 2%;
  /* padding-left: 30px; */
  padding-left: 30px;

  max-height: 100%;
  /* height: max-content; */
  /* transition: padding-top 0.3s ease-out; */
  transition: padding-left 0.1s ease-out;
}

.side-menu .main-menu .sub-menu a {
  display: block;
  margin: 5px 0;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.535);
  box-sizing: border-box;
  transition: padding 0.3s ease-out;
}

.side-menu .main-menu .sub-menu a.active {
  /* change in background */
  display: block;
  /* overflow: scroll; */
  border-radius: 10px;
  color: rgb(99, 99, 99);
}

.divider {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: rgb(109, 109, 109);
}

.container {
  width: 100%;
  margin-left: 20%;
  /* overflow-x: hidden; */
  max-width: 80%;
  transition: margin-left 0.2s ease-in;
  padding: 20px;
  box-sizing: border-box;
}

.container.inactive {
  max-width: 93%;
  margin-left: 80px;
}

.headingMiddleware {
  margin: 1em 0 0.5em 0;
	font-weight: normal;
	position: relative;
	text-shadow: 0 -1px rgba(0,0,0,0.6);
	font-size: 28px;
	line-height: 40px;
	background: rgba(53,86,129, 0.8);
	background: #1e314a;
	border: 1px solid #fff;
	padding: 5px 15px;
	color: white;
	border-radius: 0 10px 0 10px;
	box-shadow: inset 0 0 5px rgba(53,86,129, 0.5);
	font-family: 'Muli', sans-serif;
}