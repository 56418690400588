/* .navBar {
  padding-top: 20px;
  font-size: large;
  color: white;
}

.navBrand {
  margin: 10px;
  padding: 5;
  font-size: "18px";
  color: "white";
} */

/* .navBrand:hover {
  color: red;
  border-color:gray;
  border-color: gray;
  margin-bottom: 10px;
  padding-bottom: 25;
  border-bottom-width: 3px;
  text-decoration: "underline";
}
.navBrand::selection{
  color: red;
  border-color: gray;
  margin-bottom: 10px;
  padding-bottom: 25px;
  border-bottom-width: 3px;
  text-decoration: "underline";
} */
.gjs {
  border: none;
  background-color: aqua;
}
/* Theming */

/* Primary color for the background */
.gjs-one-bg {
  background-color: #78366a;
}

/* Secondary color for the text color */
.gjs-two-color {
  color: rgba(255, 255, 255, 0.7);
}

/* Tertiary color for the background */
.gjs-three-bg {
  background-color: #ec5896;
  color: white;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
  color: #ec5896;
}
.grp-wrapper {
  background-image: url("data:image/png:base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
}
.grp-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair;
}
.grp-handler {
  width: 4px;
  margin-left: -2px;
  user-select: none;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.grp-handler-close {
  color: rgba(0, 0, 0, 0.4);
  border-radius: 0 2px 10px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  text-align: center;
  width: 15px;
  height: 15px;
  margin-left: -5px;
  line-height: 10px;
  font-size: 21px;
  cursor: pointer;
}

.grp-handler-close {
  position: absolute;
  top: -17px;
}

.grp-handler-drag {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  width: 100%;
  height: 100%;
}

.grp-handler-selected .grap-handler-drag {
  background-color: rgba(255, 255, 255, 0.5);
}

.grp-handler-cp-c {
  display: none;
}

.grp-handler-selected .grp-handler-cp-c {
  display: block;
}

.grp-handler-cp-wrap {
  width: 15px;
  height: 15px;
  margin-left: -8px;
  border: 3px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: 100%;
  cursor: pointer;
}

.grp-handler-cp-wrap input[type="color"] {
  opacity: 0;
  cursor: pointer;
}
body, html {
  margin: 0;
  height: 100%;
}
