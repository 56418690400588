.selecting {
  display: flexbox;
}

span {
    display: none;
}
.svg {
    display: none;

}
.button {
    display: none;

}
.option {
    display: none;
    
}
